
import { err_str__fabricated__unknownErrorFallback } from '@xrds/isomorphic-fetch-ts/fetch'
import { ScannerConnectionStatusString } from '@xrds/payments-client-ts/src/monero-wallet-clients/WalletScannerClient_Base'
import { Wallet, Wallet_EventName } from '@xrds/xrds-libapp-ts/src/Wallets/models/Wallet'
import { new_balanceQuickLabelStringForWallet } from '@xrds/xrds-libapp-ts/src/Wallets/models/WalletDisplayable_ScanAndTxs'
import View_Web from '../../View/View.web'
import App_View_Web, { App_View_Context } from '../App_View_Web'
import { TitledInfoDisclosureCell, TitledInfoDisclosureCell__set_props__args } from '../Common/TitledInfoDisclosureCell'
import { Notepad_View } from '../Wallets/NotepadView'
import { SendMoney_View } from '../SendMoney/SendMoney_View'
import { WalletDetails_View } from '../Wallets/WalletDetailsScreen'
import { RequestsView } from '../Wallets/RequestsView'
import { InboxPage } from '../Chat/InboxPage'
import alert from '../Common/AlertPolyfill'
import { AbstractThemed } from '../Common/AbstractThemed'
import { styles_theme, themes } from '../themes'
import { Icon_Symbolic } from '../Common/Icon_Symbolic'
import { CTAButton } from '../Common/CTAButton'
import { ClickableLayer } from '../Common/_clickableView'
import { EditProducts } from '../Shopfront/EditProductsScreen'
//
export namespace WalletDisplayable
{
    export function SVGIconFor_walletIcon(
        wi: Wallet.DisplayIcon
    ): Icon_Symbolic.CustomSVG {
        switch (wi) {
            case Wallet.DisplayIcon.orange:
                return Icon_Symbolic.SVG_Icons.walletIcon32__orange
            case Wallet.DisplayIcon.yellow:
                return Icon_Symbolic.SVG_Icons.walletIcon32__yellow
            case Wallet.DisplayIcon.yellowgreen:
                return Icon_Symbolic.SVG_Icons.walletIcon32__yellowgreen
            case Wallet.DisplayIcon.green:
                return Icon_Symbolic.SVG_Icons.walletIcon32__green
            case Wallet.DisplayIcon.teal:
                return Icon_Symbolic.SVG_Icons.walletIcon32__teal
            case Wallet.DisplayIcon.lightblue:
                return Icon_Symbolic.SVG_Icons.walletIcon32__lightblue
            case Wallet.DisplayIcon.blue:
                return Icon_Symbolic.SVG_Icons.walletIcon32__blue
            case Wallet.DisplayIcon.bluepurple:
                return Icon_Symbolic.SVG_Icons.walletIcon32__bluepurple
            case Wallet.DisplayIcon.lightpurple:
                return Icon_Symbolic.SVG_Icons.walletIcon32__lightpurple
            case Wallet.DisplayIcon.pink:
                return Icon_Symbolic.SVG_Icons.walletIcon32__pink
            case Wallet.DisplayIcon.lightred:
                return Icon_Symbolic.SVG_Icons.walletIcon32__lightred
            case Wallet.DisplayIcon.red:
                return Icon_Symbolic.SVG_Icons.walletIcon32__red
            // not including default: here means the compiler will catch it if this ever becomes inexhaustive
        }
        throw new Error("Unhandled wallet icon: " + wi)
    }
}
//
export interface Feed_Wallet_Cell__set_props__args extends TitledInfoDisclosureCell__set_props__args
{
    wallet_displayIcon: Wallet.DisplayIcon
    descr: string
}

class _Feed_Wallet_Cell__Header extends TitledInfoDisclosureCell
{
    //
    descrView!: View_Web
    walletIconView!: Icon_Symbolic.View
    //
    setup()
    {
        super.setup()
        //
        const self = this
        {
            const view = self.rootContentsContainer
            view.layer.style.minHeight = `auto`
            // view.layer.style.justifyContent = 'flex-start'
        }
        {
            self.leftSideSubContainer.layer.style.flexDirection = "column"
            self.leftSideSubContainer.layer.style.gap = "13px"
        }
        {
            self.leftSideSubContainer_stackedTitlesContainer.layer.style.alignItems = "center"
            self.leftSideSubContainer_stackedTitlesContainer.layer.style.gap = "5px"
        }
        {
            const view = self.accessoriesSubContainer
            view.layer.style.flex = "inherit" // reset this since we dont need it here .. yet?

        }
        {
            let v = new Icon_Symbolic.View().init()
            self.walletIconView = v
            //
            self.walletIconView.retain() // here's an extra retain so that it doesn't get torn down after any re-confs; balanced in self.teardown() below
        }
    }

    public teardown(): void {
        super.teardown()
        const self = this
        self.walletIconView.release() // since we have an extra retain on it; I *think* this works
    }

    override _overridable_addOtherViewsToLeftSideSubContainer()
    {
        super._overridable_addOtherViewsToLeftSideSubContainer() // not that it's needed here
        const self = this
        {
            let themeC = self.ip.themeC
            let view = new View_Web({}).init()
            view.layer.style.color = themeC.current.colors.font_light
            view.layer.classList.add(themes.styles_fonts.ClassNames.ff__mono_regular)
            view.layer.style.fontSize = "18px"
            view.layer.style.justifyContent = "center"
            self.descrView = view
            self.leftSideSubContainer_stackedTitlesContainer.addSubview(view)
        }
    }
    //
    public set_props(
        args: Feed_Wallet_Cell__set_props__args
    ) { 
        const self = this
        //
        let wi = args.wallet_displayIcon
        let svg = WalletDisplayable.SVGIconFor_walletIcon(wi)
        self.walletIconView.set_props({
            svg: svg
        })
        args.leftSideSubviews = [ 
            self.walletIconView // this requires us to hold an extra .retain since ordinarily the view would be released after first .removeFromSuperview()
        ]
        //
        super.set_props(args)
        //
        self.descrView.layer.innerText = args.descr
    }
}
//
//
export interface Feed_Wallet_Cell_InitParams
{
    wallet: Wallet.Instance
    is_first_in_list: boolean
}
//
namespace Feed_Wallet_Cell__TopLevel
{
    export interface View_IP
    {
        tapped_fn?: () => void
    }
    //
    export class View 
        extends AbstractThemed.View
        implements ClickableLayer.View_Interface // TODO: convert this to a AbstractTitledControlCell
    {
        //
        // initial properties:
        _fwc_tlv_ip!: View_IP

        constructor(ip: View_IP)
        {
            super({ })
            //
            const self = this
            self._fwc_tlv_ip = ip
        }
        //
        //
        /*private */_fn__click?: (e: any) => boolean
        //
        //
        setup(): void {
            super.setup()
            //
            const self = this
            let view = self
            ClickableLayer.styleAs_clickable(self)

            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._0, isInEmbeddedGroup: false, cellVariants: [ AbstractThemed.CellGroup_Special.Variant.noMargin ] })
            view.layer.style.display = "flex"
            view.layer.style.flexDirection = "column"
            view.layer.style.alignItems = "center"
            //
            ClickableLayer._configureWith_interactivity(self, self._fwc_tlv_ip.tapped_fn) // necessary
        }
        public teardown(): void {
            super.teardown()
            const self = this
            ClickableLayer._stopObserving_click(self)
        }
        //
        // Imperatives - Interface Impl - ClickableLayer
        setEnabled(isEnabled: boolean)
        {
            const self = this
            super.setEnabled(isEnabled)
            ClickableLayer.setEnabled(self, isEnabled/*this var is actually not used*/)
        }
    }
}
//
export class Feed_Wallet_Cell extends App_View_Web
{
    ip!: Feed_Wallet_Cell_InitParams
    //
    _obs_fn_1?: () => void
    //
    container!: AbstractThemed.View
    headerCell!: _Feed_Wallet_Cell__Header
    buttonsRow!: View_Web
    //
    sendMoney_view!: CTAButton.View
    receiveMonero_view!: CTAButton.View
    encryptedMail_view!: CTAButton.View
    notepad_view!: CTAButton.View
    shopfront_view!: CTAButton.View
    //
    constructor(ctx: App_View_Context, ip: Feed_Wallet_Cell_InitParams)
    {
        super(ctx, { el_name: "div" })
        //
        const self = this
        self.ip = ip
    }
    setup(): void 
    {
        super.setup()
        const self = this
        let themeC = self.ctx.themeC
        {
            let view = self
        }
        {
            let view = new Feed_Wallet_Cell__TopLevel.View({
                tapped_fn: (
                ) => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    optl_self.ctx.rootNavigationView.push(new WalletDetails_View(optl_self.ctx, {
                        initial_selectedWalletId: optl_self.ip.wallet._id!
                    }).init())
                }
            }).init()
            self.container = view
            self.addSubview(view)
        }
        let weakSelf = new WeakRef(self)
        {
            const view = new _Feed_Wallet_Cell__Header({
                themeC: self.ctx.themeC,
                contextInUI: themes.ElementContextInUI.FeedCellHeader,
                tapped_fn: undefined
            }).init()
            view.layer.style.marginTop = "7px"
            self.headerCell = view
            self.container.addSubview(view)
        }
        {
            let view = new View_Web({}).init()
            view.layer.classList.add(styles_theme.ClassNames.buttonRow)
            view.layer.style.marginTop = "30px"
            view.layer.style.marginBottom = "7px"
            self.buttonsRow = view
            self.container.addSubview(view)
        }
        {
            let view = new CTAButton.View({
                themeC: self.ctx.themeC, 
                symbolicIcon_props: { svg: Icon_Symbolic.SVG_Icons.sendFunds },
                variant: CTAButton.Variant.secondary,
                clicked_fn: (e) => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    if (!optl_self.ip.wallet.isViewOnlyWallet()) {
                        optl_self.ctx.rootNavigationView.push(new SendMoney_View(optl_self.ctx, { 
                            initial_selectedWalletId: optl_self.ip.wallet._id!
                        }).init())
                    } else {
                        alert(
                            "View-Only Wallet", 
                            "'Spend From' is not available in view-only mode. To spend money, please log in with a 'spend key' or mnemonic.",
                            [
                                { text: 'Okay', style: 'default', onPress: () => {} }
                            ]
                        )
                    }
                }
            }).init()
            self.sendMoney_view = view
            self.buttonsRow.addSubview(view)
        }
        {
            let view = new CTAButton.View({
                themeC: self.ctx.themeC, 
                symbolicIcon_props: { svg: Icon_Symbolic.SVG_Icons.receive },
                variant: CTAButton.Variant.secondary,
                clicked_fn: (e) => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    optl_self.ctx.rootNavigationView.push(new RequestsView(optl_self.ctx, { 
                        initial_selectedWalletId: optl_self.ip.wallet._id!
                    }).init())
                }
            }).init()
            self.receiveMonero_view = view
            self.buttonsRow.addSubview(view)
        }
        {
            let view = new CTAButton.View({
                themeC: self.ctx.themeC, 
                symbolicIcon_props: { svg: Icon_Symbolic.SVG_Icons.notes },
                variant: CTAButton.Variant.secondary,
                clicked_fn: (e) => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    optl_self.ctx.rootNavigationView.push(new Notepad_View(optl_self.ctx, { 
                        initial_selectedWalletId: optl_self.ip.wallet._id!
                    }).init())
                }
            }).init()
            self.notepad_view = view
            self.buttonsRow.addSubview(view)
        }
        {
            let view = new CTAButton.View({
                themeC: self.ctx.themeC, 
                symbolicIcon_props: { svg: Icon_Symbolic.SVG_Icons.encryptedMail },
                variant: CTAButton.Variant.secondary,
                clicked_fn: (e) => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    optl_self.ctx.rootNavigationView.push(new InboxPage.View(optl_self.ctx, {
                        initial_selectedWalletId: optl_self.ip.wallet._id!
                    }).init())
                }
            }).init()
            self.encryptedMail_view = view
            self.buttonsRow.addSubview(view)
        }
        {
            let view = new CTAButton.View({
                themeC: self.ctx.themeC, 
                symbolicIcon_props: { fa: Icon_Symbolic.FontAwesome_Icons.shopLock },
                variant: CTAButton.Variant.secondary,
                clicked_fn: (e) => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    optl_self.ctx.rootNavigationView.push(new EditProducts.View(optl_self.ctx, { 
                        initial_selectedWalletId: optl_self.ip.wallet._id!
                    }).init())
                }
            }).init()
            self.shopfront_view = view
            self.buttonsRow.addSubview(view)
        }
        {
            let view = new CTAButton.View({
                themeC: self.ctx.themeC, 
                symbolicIcon_props: { fa: Icon_Symbolic.FontAwesome_Icons.arrow__right },
                variant: CTAButton.Variant.main,
                clicked_fn: (e) => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    optl_self.ctx.rootNavigationView.push(new WalletDetails_View(optl_self.ctx, {
                        initial_selectedWalletId: optl_self.ip.wallet._id!
                    }).init())
                }
            }).init()
            self.buttonsRow.addSubview(view)
        }
        AbstractThemed.updateDynamicClassesOfGroupCellsOf(self) // since group members have changed 
        //
        self._obs_fn_1 = () => 
        {
            let optl_self = weakSelf.deref()
            if (!optl_self) {
                return
            }
            optl_self._configureWithWalletState()
        }
        self.ip.wallet.on(Wallet_EventName.walletLabelChanged, self._obs_fn_1!)
        self.ip.wallet.on(Wallet_EventName.subaccountsBalancesChanged, self._obs_fn_1!)
        self.ip.wallet.on(Wallet_EventName.heightsUpdated, self._obs_fn_1!)
        self.ip.wallet.on(Wallet_EventName.booted, self._obs_fn_1!)
        self.ip.wallet.on(Wallet_EventName.errorWhileBooting, self._obs_fn_1!)
        self.ip.wallet.on(Wallet_EventName.scannerConnectionStatusUpdated, self._obs_fn_1!)
        self.ip.wallet.on(Wallet_EventName.boostate_changed, self._obs_fn_1!)
        //
        self._configureWithWalletState()
    }
    public teardown(): void
    {
        super.teardown()
        //
        const self = this
        self.ip.wallet.removeListener(Wallet_EventName.walletLabelChanged, self._obs_fn_1!)
        self.ip.wallet.removeListener(Wallet_EventName.subaccountsBalancesChanged, self._obs_fn_1!)
        self.ip.wallet.removeListener(Wallet_EventName.heightsUpdated, self._obs_fn_1!)
        self.ip.wallet.removeListener(Wallet_EventName.booted, self._obs_fn_1!)
        self.ip.wallet.removeListener(Wallet_EventName.errorWhileBooting, self._obs_fn_1!)
        self.ip.wallet.removeListener(Wallet_EventName.scannerConnectionStatusUpdated, self._obs_fn_1!)
        self.ip.wallet.removeListener(Wallet_EventName.boostate_changed, self._obs_fn_1!)
        //
        self._obs_fn_1 = undefined
    }
    //
    // Accessors
    _new_title_str(): string
    {
        const self = this
        let w = self.ip.wallet
        // TODO: probably move this to the Wallet class
        //
        let str = w.displayable_walletLabel()
        if (w.isViewOnlyWallet() == true) {
            str += " (View-Only)"
        }
        //
        return str
    }
    //
    //
    _configureWithWalletState()
    {
        const self = this
        let w = self.ip.wallet
        let descr = new_balanceQuickLabelStringForWallet(w)
        let connection_status = w.wallet_scanner_client.CachedConnectionStatusesByAddr(w.public_address!)
        if (connection_status) {
            if (connection_status.status_str == ScannerConnectionStatusString.connection_error) { // slightly redundant with w.didFailToBoot_errStr in new_balanceQuickLabelStringForWallet but probably covers more given that it covers the poll response too
                descr += " (Connection Error" 
                    + (connection_status.err_str__orUndef && connection_status.err_str__orUndef != err_str__fabricated__unknownErrorFallback 
                        ? ": " + connection_status.err_str__orUndef! 
                        : ""
                      ) 
                    + ")"
            } else if (connection_status.status_str == ScannerConnectionStatusString.connecting) {
                descr += " (" + connection_status.status_str + ")"
            }
        }
        self.headerCell.set_props({ 
            title: self._new_title_str(), 
            descr: descr,
            wallet_displayIcon: w.displayIcon!
        })
        let has_mbox_booted = w.has_mbox_booted()
        let is_view_only = w.isViewOnlyWallet() == true
        //
        self.receiveMonero_view.setEnabled(has_mbox_booted)
        self.notepad_view.setEnabled(has_mbox_booted)
        self.encryptedMail_view.setEnabled(has_mbox_booted && !is_view_only)
        self.shopfront_view.setEnabled(has_mbox_booted && !is_view_only)
    }
}
//
//



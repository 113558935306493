

import { BigInteger, monero_amount_format_utils__instance } from "@xrds/monero-utils-ts"
import { Currency } from "@xrds/xrds-libapp-ts/src/Currencies/models/constants"
import { DocumentId } from "@xrds/xrds-libapp-ts/src/Persistable/Documents"
import { Wallet, Wallet_EventName } from "@xrds/xrds-libapp-ts/src/Wallets/models/Wallet"
import { formatDate__displayableTransaction } from "@xrds/xrds-libapp-ts/src/Wallets/models/WalletDisplayable_ScanAndTxs"
import { WalletObserver } from "@xrds/xrds-libapp-ts/src/Wallets/models/WalletObserver"
import { SendParams__hasFullPrimaryDestInfo, SendParams__n_outputs, SummarizedTransaction_Status, TransactionSummaryDetails } from "@xrds/xrds-libapp-ts/src/Wallets/models/WalletRecentLocalSendTxActivity"
import View_Web from "../../View/View.web"
import { App_View_Context } from "../App_View_Web"
import alert from "../Common/AlertPolyfill"
import { CcyAmountInputCell } from "../Common/CcyAmountInputCell"
import { ComposableComponentFormPage } from "../Common/ComposableComponentFormPage"
import { InlineWarningTextCell } from "../Common/InlineWarningTextCell"
import { LargeCTAButtonsGroup } from "../Common/LargeCTAButtonsGroup"
import { PaymentIDInputCell } from "../Common/PaymentIDInputCell"
import { ToAddrInputCell } from "../Common/ToAddrInputCell"
import WalletSelect_Cell_View from "../Common/WalletSelectCell"
import { cached_BigInteger_oneHundredPctInAtomicUnits, InlineTipPicker, Tipping } from "../Common/InlineTipPickerCell"
import { RecentLocalTxSummary } from "./RecentLocalTxSummaryCell"
import WalletSubaddrMajIdxSelect_Cell_View, { SubaddrMajIdxList_DataSource, WalletSubaddrMajIdxSelect } from "../Common/WalletSubaddrMajIdxSelect_Cell"
import WalletSubaddrMinIdcsMultiSelect_Cell_View from "../Common/WalletSubaddrMinIdcsMultiSelect_Cell"
import { AbstractThemed, CellGroupSetMarker } from "../Common/AbstractThemed"
import { Icon_Symbolic } from "../Common/Icon_Symbolic"
import { CTAButton } from "../Common/CTAButton"
import { themes } from "../themes"
//
//
export interface SendMoney_View_IP
{
    initial_selectedWalletId: string
}
//
interface InlineTipPickerSelectedValuesAsBigIntegersOrUndef
{
    _supportTheApp: BigInteger|undefined
    _supportMRL: BigInteger|undefined
}
interface SubTotalDependentRetValAmounts
{ // 0 means none
    initial_subtotalToSpend: BigInteger // this is merely here for assertion that it equals the sum of the following values:
    actualPrimaryDestAmount: BigInteger|undefined // this is the original subtotal less any calculated fees; this value will be undefined if no/undef/zero ccy amount was actually input
    //
    // if either of these are undefined it actually means "None" is selected
    xmr_tipAmt_supportTheApp?: BigInteger
    xmr_tipAmt_supportMRL?: BigInteger
}
//
export class SendMoney_View extends ComposableComponentFormPage
{
    ip!: SendMoney_View_IP
    //
    // Form state - TODO: convert most of this to just accessors reading the input values instead
    select_wallet!: WalletSelect_Cell_View
    //
    input_spendFromSubaddrMajIdx!: WalletSubaddrMajIdxSelect_Cell_View
    spendFromSubaddrMajIdx(): number|undefined
    {
        return this.input_spendFromSubaddrMajIdx.selected_subaddrMajIdx()
    }
    input_spendFromSubaddrMinIdcs!: WalletSubaddrMinIdcsMultiSelect_Cell_View
    spendFromSubaddrMinIdxsCSV_OR_all(): string|undefined
    {
        return this.input_spendFromSubaddrMinIdcs.selected_subaddrMinIdxsCSV_OR_all()
    }
    //
    input_ccyAmount!: CcyAmountInputCell
    ccyAmount(): string|undefined
    {
        let v = this.input_ccyAmount.lookup_input_value()
        //
        return v == '' ? undefined : v
    }
    isMaxButtonToggledOn(): boolean {
        return this.input_ccyAmount.isMaxButtonToggledOn()
    }
    // select_ccy!: CcySelectCell_Cell_View
    selectedCcy(): Currency
    {
        return Currency.XMR 
        // return this.select_ccy.single_selected_value()
    }
    input_toAddr!: ToAddrInputCell
    toAddr(): string|undefined {
        let v = this.input_toAddr.lookup_input_value()
        v = v.replace(/\s/g, '') // once filtering is done at the control level there will be no whitespace
        //
        return v == '' ? undefined : v
    }
    input_paymentId!: PaymentIDInputCell
    paymentId(): string {
        return this.input_paymentId.lookup_input_value()
    }
    //
    primaryDestinationArea_endMarker!: View_Web
    beforePrimaryTransactionConfirmationArea_endMarker!: View_Web
    //
    TEMPORARY__behaviorFlag__disableTipsAndDonationsWhileSweep: boolean = true
    shouldAllowNormalUsageOfTipsAndDonationsUI()
    { // after implementing multi dest support in the prior core, which has the ability to specify a UINT64_MAX in the dests list, i opted to use a factorization of wallet2's tx creation for convergence purposes. 
        if (this.TEMPORARY__behaviorFlag__disableTipsAndDonationsWhileSweep == true) {
            return this.isMaxButtonToggledOn() != true
        } else {
            return true
        }
    }
    isTipAndDonationsUIActuallyIncluded()
    {
        return this.tipPicker_supportMRL.isHidden != true
    }
    //
    // PS: It's a bit of an assumption but I'm going to assume that if the MAX button is every pressed, then it means the form is generally "enabled", i.e not submitting, so, that means it's also an okay time to set whether the tipPicker should be usable - which is only a temporary thing til we get sweep support back in multidest
    _TEMPORARY_checkingFlag_excludeTipsAndDonations()
    {
        const self = this
        if (self.TEMPORARY__behaviorFlag__disableTipsAndDonationsWhileSweep) {
            self.tipPicker_supportMRL.hide()
            self.tipPicker_supportTheApp.hide()
            AbstractThemed.updateDynamicClassesOfGroupCellsOf(self) // since group members have changed 
        }
    }
    _TEMPORARY_checkingFlag_includeTipsAndDonations()
    {
        const self = this
        if (self.TEMPORARY__behaviorFlag__disableTipsAndDonationsWhileSweep) {
            self.tipPicker_supportMRL.show()
            self.tipPicker_supportTheApp.show()
            AbstractThemed.updateDynamicClassesOfGroupCellsOf(self) // since group members have changed 
        }
    }
    //
    is_still_setting_up_tip_views: boolean = true
    //
    
    //
    tipPicker_supportTheApp!: InlineTipPicker.Cell
    tipPicker_supportMRL!: InlineTipPicker.Cell
    //
    mainButtons!: LargeCTAButtonsGroup.View
    //
    new_subTotalDependentRetValAmounts(
        isSweeping: boolean,
        optl_sweepableAmount: BigInteger|undefined // when sweeping, this will be the wallet's total sendable amount; when not sweeping, it will be whatever the user has entered as their subtotal to send; it can be undef if user entered a nil/zero amount as primary dest
    ): SubTotalDependentRetValAmounts {
        const self = this
        //
        let _initial_subtotalToSpend: BigInteger
        let _initial_actualPrimaryDestAmount: BigInteger|undefined = new BigInteger(0)
        if (isSweeping != true) {
            let adjacent_ccyAmount = self.ccyAmount() // this is read from the input - which we would not want to use if we're sweeping! we want to update the input instead in that case
            if (typeof adjacent_ccyAmount === 'undefined' || adjacent_ccyAmount == '') {
                _initial_subtotalToSpend = new BigInteger(0)
                _initial_actualPrimaryDestAmount = undefined
            } else {
                _initial_subtotalToSpend = monero_amount_format_utils__instance.parseMoney(adjacent_ccyAmount!)
                _initial_actualPrimaryDestAmount = new BigInteger(0) // will be cald'd below
            }
        } else {
            _initial_subtotalToSpend = optl_sweepableAmount!
            _initial_actualPrimaryDestAmount = optl_sweepableAmount! // to be finalized below with tip amounts
        }
        //
        let accessoryAmounts: SubTotalDependentRetValAmounts = // finalized below
        { // undef means none - 0 means 0
            initial_subtotalToSpend: _initial_subtotalToSpend,
            actualPrimaryDestAmount: _initial_actualPrimaryDestAmount, // to be finalized as below; if this is initialized to undef, it will be returned as undef, and treated as nothing in calculations
            //
            // just some dummy initial values for convenience; gonna rely on logic remaining sound
            xmr_tipAmt_supportTheApp: undefined, 
            xmr_tipAmt_supportMRL: undefined
        }
        //
        // now calculate tips in a manner that properly handles sweeping and percentages
        if (self.is_still_setting_up_tip_views != true) { // then avoid attempting to read the values and assume everything remains at their initial or zero values
            let isTipsAndDonationsActuallyIncluded = self.isTipAndDonationsUIActuallyIncluded()
            let selectedValuesAs: InlineTipPickerSelectedValuesAsBigIntegersOrUndef = 
            { // merely caching parsed vals
                _supportTheApp: (!isTipsAndDonationsActuallyIncluded ? undefined : InlineTipPicker.new_selectedValue_asBigInteger_orUndefForNone(self.tipPicker_supportTheApp.selectedValue_primary())),
                _supportMRL: (!isTipsAndDonationsActuallyIncluded ? undefined : InlineTipPicker.new_selectedValue_asBigInteger_orUndefForNone(self.tipPicker_supportMRL.selectedValue_primary()))
            }
            if (isSweeping) {
    // NOTE:  I dont have a good reason for subtracting pctgs before fixed amounts here - more that it seemed slightly more intuitive
                let tipPctgsToSubtract: BigInteger[] = []
                if (isTipsAndDonationsActuallyIncluded) {
                    { // _supportTheApp
                        let tipPicker = self.tipPicker_supportTheApp
                        if (tipPicker.isToggled_pctsNotAmts()) {
                            if (typeof selectedValuesAs._supportTheApp !== 'undefined') {
                                tipPctgsToSubtract.push(selectedValuesAs._supportTheApp)
                            }
                        }
                    }
                    { // _supportMRL
                        let tipPicker = self.tipPicker_supportMRL
                        if (tipPicker.isToggled_pctsNotAmts()) {
                            if (typeof selectedValuesAs._supportMRL !== 'undefined') {
                                tipPctgsToSubtract.push(selectedValuesAs._supportMRL)
                            }
                        }
                    }
                }
                let sum_tipPctgsToSubtract = new BigInteger(0)
                for (let tipPctg of tipPctgsToSubtract) {
                    sum_tipPctgsToSubtract.add(tipPctg)
                }
                // now the pctg based fees have been subtracted; handle the fixed amts too..
                // gonna record final xmr values here too
                if (!self.tipPicker_supportTheApp.isToggled_pctsNotAmts()) {
                    accessoryAmounts.xmr_tipAmt_supportTheApp = selectedValuesAs._supportTheApp // may be undefined
                } else {
                    if (typeof selectedValuesAs._supportTheApp !== 'undefined') {
                        accessoryAmounts.xmr_tipAmt_supportTheApp = accessoryAmounts.initial_subtotalToSpend.multiply(selectedValuesAs._supportTheApp).divide(cached_BigInteger_oneHundredPctInAtomicUnits)
                    }
                }
                if (!self.tipPicker_supportMRL.isToggled_pctsNotAmts()) {
                    accessoryAmounts.xmr_tipAmt_supportMRL = selectedValuesAs._supportMRL // may be undefined
                } else {
                    if (typeof selectedValuesAs._supportMRL !== 'undefined') {
                        accessoryAmounts.xmr_tipAmt_supportMRL = accessoryAmounts.initial_subtotalToSpend.multiply(selectedValuesAs._supportMRL).divide(cached_BigInteger_oneHundredPctInAtomicUnits)
                    }
                }
                if (typeof accessoryAmounts.actualPrimaryDestAmount !== 'undefined') {
                    if (typeof accessoryAmounts.xmr_tipAmt_supportTheApp !== 'undefined') {
                        accessoryAmounts.actualPrimaryDestAmount = accessoryAmounts.actualPrimaryDestAmount!.subtract(accessoryAmounts.xmr_tipAmt_supportTheApp)
                    }
                    if (typeof accessoryAmounts.xmr_tipAmt_supportMRL !== 'undefined') {
                        accessoryAmounts.actualPrimaryDestAmount = accessoryAmounts.actualPrimaryDestAmount!.subtract(accessoryAmounts.xmr_tipAmt_supportMRL)
                    }
                    if (accessoryAmounts.actualPrimaryDestAmount!.compare(new BigInteger(0)) < 0) { // min to 0
                        accessoryAmounts.actualPrimaryDestAmount = new BigInteger(0) // set to 0
                    }
                }
            } else {
                if (typeof selectedValuesAs._supportTheApp !== 'undefined') {
                    accessoryAmounts.xmr_tipAmt_supportTheApp = Tipping.convenience_NONSWEEPING_calcd_tipAmt(
                        accessoryAmounts.initial_subtotalToSpend,
                        selectedValuesAs._supportTheApp, 
                        self.tipPicker_supportTheApp.isToggled_pctsNotAmts()
                    )
                }
                if (typeof selectedValuesAs._supportMRL !== 'undefined') {
                    accessoryAmounts.xmr_tipAmt_supportMRL = Tipping.convenience_NONSWEEPING_calcd_tipAmt(
                        accessoryAmounts.initial_subtotalToSpend,
                        selectedValuesAs._supportMRL, 
                        self.tipPicker_supportMRL.isToggled_pctsNotAmts()
                    )
                }
                accessoryAmounts.actualPrimaryDestAmount = typeof accessoryAmounts.actualPrimaryDestAmount == 'undefined' ? undefined : accessoryAmounts.initial_subtotalToSpend
            }
        } else {
            // console.log("Still setting up fields - not calculating real values")
        }
        if (isSweeping) {
// TODO: we should probably assert here that the subtotalDependentAmts.actualPrimaryDestAmount plus the re-queried tip amounts actually equals the subtotalDependentAmts.initial_subtotalToSpend here
        }
        //
        return accessoryAmounts
    }
    //
    //
    // Views
    formInputsAndWarnings_endMarker!: View_Web
    currentlyPreppingAction_cells: View_Web[] = []
    transactionHistory_cells: View_Web[] = []
    //
    wo!: WalletObserver
    transactionHistory(): TransactionSummaryDetails[]
    {
        const self = this
        //
        return self.wo.selectedWallet()!.recentSendLocalSendTxActivity.transactionHistory
    }
    //
    constructor(ctx: App_View_Context, ip: SendMoney_View_IP)
    {
        super(ctx)
        const self = this
        self.ip = ip
    }
    setup(): void 
    {
        super.setup()
        //
        const self = this
        self.setup_wo() // first; so that components have access to initial selected wallet if it must be selected at setup
        self.setup_views()
        //
        if (self.is_still_setting_up_tip_views) {
            let ccy = self.selectedCcy()
            // now that all views are set up.. and the setup boolean is in place.. side effects wont cause crashes
            console.log("setup(): self.ctx.controllers.settingsController.supportTheApp_isToggled_pctsNotAmts", self.ctx.controllers.settingsController.supportTheApp_isToggled_pctsNotAmts)

            self.tipPicker_supportTheApp.set_props__tip({
                ccyIfNotPct: ccy,
                isToggled_pctsNotAmts: self.ctx.controllers.settingsController.supportTheApp_isToggled_pctsNotAmts,
                selectedValue: self.ctx.controllers.settingsController.supportTheApp_selectedValue
            })
            console.log("setup(): self.ctx.controllers.settingsController.supportMRL_isToggled_pctsNotAmts", self.ctx.controllers.settingsController.supportMRL_isToggled_pctsNotAmts)
            self.tipPicker_supportMRL.set_props__tip({
                ccyIfNotPct: ccy,
                isToggled_pctsNotAmts: self.ctx.controllers.settingsController.supportMRL_isToggled_pctsNotAmts,
                selectedValue: self.ctx.controllers.settingsController.supportMRL_selectedValue
            })
        }
        self.is_still_setting_up_tip_views = false
        //
        self.configureWith_selectedCcy() // for now, this is just XMR
        self.configureWith_formSubmittingState({}) // this is in case the user has previously hit Back after starting a tx and then returned to the Send Money screen while it is still in progress
        self.configureWith_formSubmitPrepProgress()
        self.configureWith_recentLocalSendTxActivity()
    }
    setup_wo()
    {
        const self = this
        let weakSelf = new WeakRef(self) // so as not to cause a strong ref cycle
        self.wo = new WalletObserver({
            walletId: self.ip.initial_selectedWalletId, // singleSelectable mode; this will track the wallet select's wallet
            wlc: self.ctx.controllers.walletsListController,
            obs_fns_by_eventName: {
                [Wallet_EventName.subaccountsBalancesChanged]: () =>
                {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    if (optl_self.isMaxButtonToggledOn()) {
                        optl_self._updateAmountsStateAndMaxBtnFlagHaving_pressed_maxButton() // simply update the balance and amounts
                    }
                },
                [Wallet_EventName.recentLocalSendTxActivity_updated]: () =>
                {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    optl_self.configureWith_recentLocalSendTxActivity()
                }
            }
        })
    }
    setup_views()
    {
        const self = this
        let weakSelf = new WeakRef(self) // key so as not to cause a strong ref cycle
        {
            let view = new WalletSelect_Cell_View({
                themeC: self.ctx.themeC,
                wlc: self.ctx.controllers.walletsListController,
                //
                title: "Spend From Wallet", 
                initial_selectedWalletId: self.wo.selectedWalletId,
                showBalances: false,
                showAddress: true,
                //
                did_change_to_wallet_w_id__fn: (w: Wallet.Instance|undefined, _id: DocumentId) =>
                {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    optl_self.wo.setSelectedWalletId_andStartObserving(_id)
                    optl_self.input_spendFromSubaddrMajIdx.setSelectedWalletId(_id)
                    optl_self.input_spendFromSubaddrMinIdcs.setSelectedWalletId(_id)
                    // Now that the subaddr maj and min(s) are set, do balance related stuff:
                    // 
                    if (optl_self.isMaxButtonToggledOn()) { // then unset the max btn because the sweepable amount will likely differ and we might as well have them hit 'max' again since interacting with it is potentially fairly strongly associated with the prior selected wallet
                        optl_self.input_ccyAmount.set_props({ ccyAmount: {
                            isMaxButtonToggledOn: false,
                            ccy: undefined, // no change
                            title: undefined, // no change
                            tooltipText: undefined
                        } })
                    }
                    //
                    optl_self.configureWith_formSubmitPrepProgress()
                    optl_self.configureWith_recentLocalSendTxActivity()
                    optl_self.configureWith_formSubmittingState({}) // since different wallets can send simultaneously
                }
            }).init()
            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._0, isInEmbeddedGroup: false })
            self.select_wallet = view
            self.addSubview(view)
        }
        {
            let view = new WalletSubaddrMajIdxSelect_Cell_View({
                themeC: self.ctx.themeC,
                wlc: self.ctx.controllers.walletsListController,
                //
                subaddrListSource: SubaddrMajIdxList_DataSource.accountBalanceSums, // as it's a spend-from
                tooltipTextVariationForContext: WalletSubaddrMajIdxSelect.TooltipTextVariationForContext.SendForm,
                //
                title: "From Account Major Index", 
                initial_selectedWalletId: self.wo.selectedWalletId,
                initial_selectedSubaddrMajIdx: null, // it should pick up idx 0 
                //
                did_change_to_subaddr_maj_idx__w_wallet_id__fn: (maj_i: number|null) =>
                {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    if (maj_i === null) {
                        throw new Error("Never expecting null maj_i in this subaddr maj select mode")
                    }
                    // first update the min idx select control so that its selection will be available (if synchronously applied) before calculating max stuff
                    if (typeof optl_self.input_spendFromSubaddrMinIdcs !== 'undefined') { // since it may not have been initialized yet - on the very first call during init
                        optl_self.input_spendFromSubaddrMinIdcs.setSelectedSpendFromSubaddrMajIdx("" + maj_i)
                    }
                    //
                    if (optl_self.isMaxButtonToggledOn()) { // then recalc the amts etc
                        optl_self._updateAmountsStateAndMaxBtnFlagHaving_pressed_maxButton()
                    }
                    optl_self.configureWith_formSubmitPrepProgress() 
                    optl_self.configureWith_recentLocalSendTxActivity()
                }
            }).init()
            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._0, isInEmbeddedGroup: false })
            self.input_spendFromSubaddrMajIdx = view
            self.addSubview(view)
        }
        {
            let i = parseInt(self.input_spendFromSubaddrMajIdx.single_selected_value())
            //
            let view = new WalletSubaddrMinIdcsMultiSelect_Cell_View({
                themeC: self.ctx.themeC,
                wlc: self.ctx.controllers.walletsListController,
                //
                title: "From Account Minor Indexes", 
                initial_selectedWalletId: self.wo.selectedWalletId,
                initial_selectedSubaddrMajIdx: i, // it should pick up the first available index
                initial_selectedSubaddrMajIdcs: null,
                //
                did_change_to_subaddr_min_idcs__w_wallet_id__fn: () =>
                {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    if (optl_self.isMaxButtonToggledOn()) { // then recalc the amts etc
                        optl_self._updateAmountsStateAndMaxBtnFlagHaving_pressed_maxButton()
                    }
                    optl_self.configureWith_formSubmitPrepProgress() 
                    optl_self.configureWith_recentLocalSendTxActivity()
                }
            }).init()
            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._0, isInEmbeddedGroup: false })
            self.input_spendFromSubaddrMinIdcs = view
            self.addSubview(view)
        }
        // {
        //     let view = new CcySelectCell_View({ 
        //         themeC: self.ctx.themeC,
        //         title: "To Currency",
        //         ccySelectListType: CcySelectListTypes.ALL_SEND_OR_RECV,
        //         selectedCcy: undefined, // it will default to .XMR
        //         did_change_value__fn: (l: string) =>
        //         {
        //             let optl_self = weakSelf.deref()
        //             if (!optl_self) {
        //                 return
        //             }
        //             // let selectedCcy = l as Currency
        //             optl_self.configureWith_selectedCcy()
        //             optl_self.configureWith_formSubmitPrepProgress()
        //         }
        //     }).init()
        //     self.select_ccy = view
        //     self.group_main.addSubview(view)
        // }
        {
            let view = new CcyAmountInputCell({
                themeC: self.ctx.themeC,
                show_maxButton: true,
                amount_input__changed_fn: (v: string, textInput_is_focused: boolean) => 
                {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }

/// TODO: is this bugged? would it not update on a max button press?

                    optl_self.configureWith_formSubmitPrepProgress() 
                },
                changed__isMaxButtonToggledOn_fn: (is: boolean) =>
                {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    if (is) {
                        optl_self._TEMPORARY_checkingFlag_excludeTipsAndDonations() // PS: It's a bit of an assumption but I'm going to assume that if the MAX button is every pressed, then it means the form is generally "enabled", i.e not submitting, so, that means it's also an okay time to set whether the tipPicker should be usable - which is only a temporary thing til we get sweep support back in multidest
                        optl_self._updateAmountsStateAndMaxBtnFlagHaving_pressed_maxButton()
                    } else {
                        optl_self._TEMPORARY_checkingFlag_includeTipsAndDonations()
                        optl_self._updateAmountsStateAndMaxBtnFlagHaving_un_pressed_maxButton()
                    }
                }
            }).init()
            view.set_cellTheme({ 
                p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: true,
                explicitZeroEmbGroupBorderRadius_top: true
            })
            self.input_ccyAmount = view
            self.addSubview(view)
        }
        {
            let view = new ToAddrInputCell({
                themeC: self.ctx.themeC,
                tooltipText: "A Monero (XMR) wallet address or subaddress",
                input__changed_fn: (v: string) => 
                {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    optl_self.configureWith_formSubmitPrepProgress()
                }
            }).init()
            // this doesn't need to be done here because it's done after setup_views via .setup above
            // // view.set_props({
            // //     to_ccy: self.selectedCcy() // we can query this because self.select_ccy is already set 
            // // })
            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: true })
            self.input_toAddr = view
            self.addSubview(view)
        }
        {
            let view = new PaymentIDInputCell({
                themeC: self.ctx.themeC,
                monero_core_bridge_instance: self.ctx.controllers.monero_core_bridge_instance,
                //
                input__changed_fn: (v: string) => 
                {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    optl_self.configureWith_formSubmitPrepProgress()
                }
            }).init()
            // this doesn't need to be done here because it's done after setup_views via .setup above
            // // view.set_props({
            // //     to_ccy: self.selectedCcy() // we can query this because self.select_ccy is already set 
            // // })
            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: true })
            self.input_paymentId = view
            self.addSubview(view)
        }
        {
            let v = new CellGroupSetMarker.View({}).init()
            self.primaryDestinationArea_endMarker = v
            self.addSubview(v)
        }
        {
            let view = new InlineTipPicker.Cell({
                themeC: self.ctx.themeC,
                control_title: "Donate to Monero Research Fund?",
                control_tooltipText: "We believe the Monero project deserves dedicated attention from qualified computer theory and security researchers. We also believe those researchers have better things to do than worry about raising money online every quarter to pay for groceries and healthcare. That's why we're starting the Monero Research Fund."
                + " Our goal is to guarantee the funding for at least one full-time dedicated researcher per year and help ensure the legendary research capacity of the Monero Research Lab. Monero has come a long way, and while it still has a few hard problems to solve to stay on top, it no longer has the full-time independent, dedicated theory research contributors it once had. Discussion around e.g. replacing ring sigs, making complex transactions safe, etc. may have stalled somewhat since 2020, but researchers around the world have continued publishing improvements to what we thought was possible for private systems. Monero technology is still catching up in 2024."
                + "<br/><br/>Donations are sent within the same transaction, as an additional output. You can also leave the 'To' field blank to just send donations.",
                yieldNewPropsForState_fn: (p) => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    optl_self.ctx.controllers.settingsController.Set_settings_valuesByKey_andSave_andNotify({
                        supportMRL_selectedValue: optl_self.tipPicker_supportMRL.selectedValue_primary(),
                        supportMRL_isToggled_pctsNotAmts: optl_self.tipPicker_supportMRL.isToggled_pctsNotAmts()
                    })
                    if (optl_self.isMaxButtonToggledOn()) {
                        optl_self._updateAmountsStateAndMaxBtnFlagHaving_pressed_maxButton() // we can simply call this first to update the balance and amounts
                    }
                    optl_self.configureWith_formSubmitPrepProgress() // this will obtain the tip amount 
                }
            }).init()
            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: false })
            self.tipPicker_supportMRL = view
            self.addSubview(view)
        }
        {
            let view = new InlineTipPicker.Cell({
                themeC: self.ctx.themeC,
                control_title: "Support Crossroads?",
                control_tooltipText: "Because we provide free blockchain scanning and redundant backups for your metadata and inbox, we currently depend on community support to pay a modest hosting bill."
                + "<br/><br/>Consider being a part of making our mission and certain highly exciting imminent features possible by supporting us with a small donation."
                + " Before Crossroads, some of us ran other pioneering high-scale Monero community services at no profit while open-sourcing key tech for Monero like the first embeddable Monero wallet client libs and the lightwallet API standard."
                + " We have designed our company to remain founder-directed (and team-owned) because our long-term product vision may challenge the status quo, and because our by-laws require records of any secret view keys we store for you to be deleted if we ever exit, or change leadership or policy."
                + " We see this app as an experiment in business models which will contribute back to the Monero project rather than those based on conflicts of interest to the project."
                + "<br/><br/>Donations are sent within the same transaction, as an additional output. You can also leave the 'To' field blank to just send donations.",
                yieldNewPropsForState_fn: (p) => {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return
                    }
                    optl_self.ctx.controllers.settingsController.Set_settings_valuesByKey_andSave_andNotify({
                        supportTheApp_selectedValue: optl_self.tipPicker_supportTheApp.selectedValue_primary(),
                        supportTheApp_isToggled_pctsNotAmts: optl_self.tipPicker_supportTheApp.isToggled_pctsNotAmts()
                    })
                    if (optl_self.isMaxButtonToggledOn()) {
                        optl_self._updateAmountsStateAndMaxBtnFlagHaving_pressed_maxButton() // we can simply call this first to update the balance and amounts
                    }
                    optl_self.configureWith_formSubmitPrepProgress() // this will obtain the tip amount - but it will not be able to set the Amount input correctly, thus the just-above call
                }
            }).init()
            view.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: false })
            self.tipPicker_supportTheApp = view
            self.addSubview(view)
        }
        {
            let v = new CellGroupSetMarker.View({}).init()
            self.beforePrimaryTransactionConfirmationArea_endMarker = v
            self.addSubview(v)
        }
        {
            let v = new LargeCTAButtonsGroup.View({ 
                themeC: self.ctx.themeC,
                contextInUI: themes.ElementContextInUI.FormControl_withinCellGroup,
                layoutDirection: LargeCTAButtonsGroup.FlowDirection.col // since we only have one button for now
            }).init()
            let descs: LargeCTAButtonsGroup.Button_Desc[] = []
            // Eventually update this when ccy changes as done previously
            let ccy = self.selectedCcy()
            if (ccy === Currency.XMR) {
                let weakSelf = new WeakRef(self)
                descs.push({
                    title: "Send Money",
                    is_default: true,
                    variant: CTAButton.Variant.main,
                    symbolicIcon_props: { svg: Icon_Symbolic.SVG_Icons.sendFunds },
                    pressed_fn: () => {
                        let optl_self = weakSelf.deref()
                        if (!optl_self) {
                            return
                        }
                        optl_self.didPress_send()
                    }
                })
            } else {
                throw new Error("Unexpected currency")
            }
            v.set_props({
                descs: descs
            })
            v.set_cellTheme({ p: AbstractThemed.GroupPrecedence._1, isInEmbeddedGroup: true })
            self.mainButtons = v
            self.addSubview(v)
        }
        {
            let view = new InlineWarningTextCell({
                themeC: self.ctx.themeC,
                uiContext: themes.ElementContextInUI.FormControl_outsideOfCellGroup
            }).init()
            self.warningText = view
            self.addSubview(view)
        }
        {
            let v = new CellGroupSetMarker.View({}).init()
            self.formInputsAndWarnings_endMarker = v
            self.addSubview(v)
        }
        AbstractThemed.updateDynamicClassesOfGroupCellsOf(self) // since group members have changed 
    }
    public teardown(): void 
    {
        super.teardown()
        const self = this
        //
        self.currentlyPreppingAction_cells = []
        //
        self.wo.teardown() // pretty important 
    }
    //
    isNoRequiredPrimaryDestInputEmpty(): boolean
    {
        const self = this
        //
        return self.input_ccyAmount.lookup_input_value() !== '' && self.input_toAddr.lookup_input_value() !== ''
    }
    areBothPrimaryDestInputsEmpty(): boolean
    {
        const self = this
        //
        return self.input_ccyAmount.lookup_input_value() === '' && self.input_toAddr.lookup_input_value() === ''
    }
    override lookup_isFormSubmitting(): boolean
    {
        const self = this
        //
        return self.wo.selectedWallet()!.recentSendLocalSendTxActivity.lookup_isCurrentlySendingFunds()
    }
    lookup_recentSendLocalSendTxActivity_status(): SummarizedTransaction_Status {
        const self = this
        //
        return self.wo.selectedWallet()!.recentSendLocalSendTxActivity.lookup_latestStatus()
    }
    isEnabled_formSubmitButtons()
    {
        const self = this
        let status = self.lookup_recentSendLocalSendTxActivity_status()
        let i = self.lookup_isFormSubmitting() != true 
            && (self.areBothPrimaryDestInputsEmpty() || self.isNoRequiredPrimaryDestInputEmpty()) // so only enable if both inputs are empty or neither are
            && (status != SummarizedTransaction_Status.SENDING && status != SummarizedTransaction_Status.PROCESSING)
        ;
        return i
    }
    //
    _updateAmountsStateAndMaxBtnFlagHaving_pressed_maxButton()
    {
        const self = this
        let isTipsAndDonationsActuallyIncluded = self.isTipAndDonationsUIActuallyIncluded()
        let n_addtl_nonprimary_dests = 0
            + (typeof (!isTipsAndDonationsActuallyIncluded ? undefined : InlineTipPicker.new_selectedValue_asBigInteger_orUndefForNone(self.tipPicker_supportTheApp.selectedValue_primary())) !== 'undefined' ? 1 : 0)
            + (typeof (!isTipsAndDonationsActuallyIncluded ? undefined : InlineTipPicker.new_selectedValue_asBigInteger_orUndefForNone(self.tipPicker_supportMRL.selectedValue_primary())) !== 'undefined' ? 1 : 0)
        let totalSendable_sweepAmt = new BigInteger(self.wo.selectedWallet()!.new_sweep_estimatedDisplayableXMRAmountAtomicUnitsString(
            n_addtl_nonprimary_dests,
            self.spendFromSubaddrMajIdx(),
            self.spendFromSubaddrMinIdxsCSV_OR_all()
        ))
        let subtotalDependentAmts = self.new_subTotalDependentRetValAmounts(true, totalSendable_sweepAmt)
        if (typeof subtotalDependentAmts.actualPrimaryDestAmount == 'undefined') {
            throw new Error("subtotalDependentAmts.actualPrimaryDestAmount should never be undefined calculating value having pressed MAX button")
        }
        let formatted_ccyAmount = monero_amount_format_utils__instance.formatMoney(subtotalDependentAmts.actualPrimaryDestAmount!) // sweepable amount less the calcd tips
        self.input_ccyAmount.setInputValue(formatted_ccyAmount)
        //
        self.configureWith_formSubmitPrepProgress() // though this probably gets triggered anyway
    }
    _updateAmountsStateAndMaxBtnFlagHaving_un_pressed_maxButton()
    {
        const self = this
        self.input_ccyAmount.setInputValue('')
        //
        self.configureWith_formSubmitPrepProgress()
    }
    //
    //
    override configureWith_formSubmissionResult(args: { validationErrStr?: string | undefined }): void 
    {
        super.configureWith_formSubmissionResult(args)
        const self = this
    }
    override configureWith_formSubmittingState(args: {}): void
    {
        const self = this
        //
        let isEnabled = self.lookup_isFormSubmitting() != true
        ///// self.select_wallet.setEnabled(isEnabled) // I am allowing this to always be enabled because the user might actually want to switch wallets while sending from another one
        self.input_spendFromSubaddrMajIdx.setEnabled(isEnabled)
        self.input_spendFromSubaddrMinIdcs.setEnabled(isEnabled)
        // self.select_ccy.setEnabled(isEnabled)
        //
        self.input_ccyAmount.setEnabled(isEnabled)
        self.input_toAddr.setEnabled(isEnabled)
        self.input_paymentId.setEnabled(isEnabled)
        //
        let tipPicker_spcfic__isEnabled  = isEnabled 
            && self.shouldAllowNormalUsageOfTipsAndDonationsUI() // This is gross but - a temporary hack here around the tip/donation interface til we get multidest sweep support in wallet2 tx code:
        ;
        self.tipPicker_supportTheApp.setEnabled(tipPicker_spcfic__isEnabled) 
        self.tipPicker_supportMRL.setEnabled(isEnabled)
        {
            let buttons__is = self.isEnabled_formSubmitButtons()
            self.mainButtons.setEnabled(buttons__is)
            /// for (let v of self.currentlyPreppingAction_cells) { // this briefly included the buttonsGroup_formCTA
            ///     v.setEnabled(buttons__is)
            /// }
        }
        super.configureWith_formSubmittingState(args) 
    }
    //
    configureWith_selectedCcy()
    {
        const self = this
        let ccy = self.selectedCcy()
        //
        let wasMaxPressed = self.input_ccyAmount.isMaxButtonToggledOn()
        let willMaxBePressed = wasMaxPressed ? ccy == Currency.XMR ? true : false : false
        if (wasMaxPressed && !willMaxBePressed) {
            let to_ccyAmount = ''
            self.input_ccyAmount.setInputValue(to_ccyAmount)
        } else {
            // leave it as is
        }
        let to_isMaxButtonToggledOn = willMaxBePressed
        self.input_ccyAmount.set_props({ ccyAmount: {
            isMaxButtonToggledOn: to_isMaxButtonToggledOn != self/*.input_ccyAmount*/.isMaxButtonToggledOn() ? to_isMaxButtonToggledOn : undefined/*no change*/,
            ccy: ccy, // generally should always set this here
            title: undefined, // no change
            tooltipText: undefined
        } })

        // might as well update the tip pickers here regardless of whether ccyAmount was really changed 
        self.tipPicker_supportTheApp.set_props__tip({
            ccyIfNotPct: ccy, // this is considered to have changed
            isToggled_pctsNotAmts: undefined, // this did not change
            selectedValue: undefined // this wouldn't have changed
        })
        self.tipPicker_supportMRL.set_props__tip({
            ccyIfNotPct: ccy, // this is considered to have changed
            isToggled_pctsNotAmts: undefined, // this did not change
            selectedValue: undefined // this wouldn't have changed
        })
        //
        self.input_toAddr.set_props({ toAddr: { // updates the placeholder
            to_ccy: ccy
        } })
        self.input_paymentId.set_props({ paymentID: { // since some currencies dont have payment IDs
            to_ccy: ccy
        } })
    }
    configureWith_formSubmitPrepProgress()
    {
        const self = this
        //
        let selectedWallet = self.wo.selectedWallet()!
        let selectedCcy = self.selectedCcy()
        let isSweeping = self.isMaxButtonToggledOn()
        let isTipsAndDonationsActuallyIncluded = self.isTipAndDonationsUIActuallyIncluded()
        let n_addtl_nonprimary_dests = 0
            + (typeof (!isTipsAndDonationsActuallyIncluded ? undefined : InlineTipPicker.new_selectedValue_asBigInteger_orUndefForNone(self.tipPicker_supportTheApp.selectedValue_primary())) !== 'undefined' ? 1 : 0)
            + (typeof (!isTipsAndDonationsActuallyIncluded ? undefined : InlineTipPicker.new_selectedValue_asBigInteger_orUndefForNone(self.tipPicker_supportMRL.selectedValue_primary())) !== 'undefined' ? 1 : 0)
        let totalSendable_amt: BigInteger | undefined = isSweeping 
            ? new BigInteger(selectedWallet.new_sweep_estimatedDisplayableXMRAmountAtomicUnitsString(n_addtl_nonprimary_dests, self.spendFromSubaddrMajIdx(), self.spendFromSubaddrMinIdxsCSV_OR_all()))
            : typeof self.ccyAmount() == 'undefined' 
                ? undefined 
                : monero_amount_format_utils__instance.parseMoney(self.ccyAmount()!)
        let toAddr = self.toAddr()
        let subtotalDependentAmts = self.new_subTotalDependentRetValAmounts(isSweeping, totalSendable_amt)
        let xmr_tipAmt_supportTheApp = subtotalDependentAmts.xmr_tipAmt_supportTheApp
        let xmr_tipAmt_supportMRL = subtotalDependentAmts.xmr_tipAmt_supportMRL
        // console.log("totalSendable_amt.compare(new BigInteger(0)) ? " , totalSendable_amt ? totalSendable_amt.compare(new BigInteger(0)) : "nil");
        // console.log("toAddr '" + toAddr + "'")
        let hasFullPrimaryDestInfo = SendParams__hasFullPrimaryDestInfo(toAddr, totalSendable_amt)
        let isReadyToSend = 
            typeof xmr_tipAmt_supportTheApp !== 'undefined' && xmr_tipAmt_supportTheApp.compare(new BigInteger(0)) == 0
                ? false // No 0 tip amounts may be sent - that must mean the amount is not sufficient for a user intended tip
                : typeof xmr_tipAmt_supportMRL !== 'undefined' && xmr_tipAmt_supportMRL.compare(new BigInteger(0)) == 0
                    ? false // No 0 tip amounts may be sent - that must mean the amount is not sufficient for a user intended tip
                    : hasFullPrimaryDestInfo
                        ? true // if full primary dest entered, ready, regardless of tips
                        : (typeof toAddr == 'undefined' || !toAddr) && (typeof totalSendable_amt == 'undefined' || !totalSendable_amt || totalSendable_amt.compare(new BigInteger(0)) == 0) // if neither of the primary dest amts are in, then check existence of tips
                            ? (typeof xmr_tipAmt_supportTheApp !== 'undefined' || typeof xmr_tipAmt_supportMRL !== 'undefined')  // at least one tip amount
                                && (((typeof xmr_tipAmt_supportTheApp == 'undefined' || xmr_tipAmt_supportTheApp.compare(new BigInteger(0)) != 0) 
                                || (typeof xmr_tipAmt_supportMRL == 'undefined' || xmr_tipAmt_supportMRL.compare(new BigInteger(0)) != 0))
                            ) // if either of tips are non-0, ready
                            : false // no primary, no tips - not ready
        ;
        let n_outputs = SendParams__n_outputs(
            hasFullPrimaryDestInfo,
            subtotalDependentAmts.xmr_tipAmt_supportTheApp,
            subtotalDependentAmts.xmr_tipAmt_supportMRL
        )
        const networkFee = new BigInteger(self.ctx.controllers.monero_core_bridge_instance.estimated_tx_network_fee(
            null, // deprecated - remove soon
            1,
            selectedWallet.wallet_scanner_client.cached__est_per_b_fee__for_addr(selectedWallet.public_address!) || "24658", 
            n_outputs
        )).toString()
        //
        self.record_reconstitutable_scrollTop_forNavigatorPopOrRemoval() // so that the page wont jump to the top when this updates
        //
        for (let v of self.currentlyPreppingAction_cells) { // this includes the CTA buttons 
            v.removeFromSuperview()
        }
        self.currentlyPreppingAction_cells = []
        //
        if (!self.lookup_isFormSubmitting()) {
            let status = self.lookup_recentSendLocalSendTxActivity_status()
            if (status == SummarizedTransaction_Status.NOT_READY || status == SummarizedTransaction_Status.READY || status == SummarizedTransaction_Status.unknown) {
                let transactionDetails: TransactionSummaryDetails = {
                    local_senderCustomRefToken: undefined, // not used here
                    //
                    status: isReadyToSend ? SummarizedTransaction_Status.READY : status, // mock the .READY status,
                    //
                    ccyAmount: typeof subtotalDependentAmts.actualPrimaryDestAmount == 'undefined' ? undefined : monero_amount_format_utils__instance.formatMoney(subtotalDependentAmts.actualPrimaryDestAmount!), // will be undef if field value is ''
                    selectedCcy: self.selectedCcy(),
                    toAddr: self.toAddr(), // will be undefined if field input is ''

                    fromAddr: self.wo.selectedWallet()!.public_address!,
                    fromWalletLabel: self.wo.selectedWallet()!.displayable_walletLabel(),
                    fromSubaddrMajI: self.spendFromSubaddrMajIdx()!,
                    fromSubaddrMinIdcsCSV_or_all: self.spendFromSubaddrMinIdxsCSV_OR_all()!,

                    paymentId: self.paymentId(),
                    //
                    networkFee: networkFee,
                    // TODO: the following 0 -> '' conversion should be factored
                    supportTheApp_amt_atmcUnitsStr: typeof subtotalDependentAmts.xmr_tipAmt_supportTheApp !== 'undefined' ? subtotalDependentAmts.xmr_tipAmt_supportTheApp.toString() : undefined,
                    supportMRL_amt_atmcUnitsStr: typeof subtotalDependentAmts.xmr_tipAmt_supportMRL !== 'undefined' ? subtotalDependentAmts.xmr_tipAmt_supportMRL.toString() : undefined,
                    //
                    secretKey: undefined,
                    tx_hash: undefined,
                    //
                    processingTxSummaryUUID: "", // TODO: make optl...?
                    updated_at: new Date(),
                    err_msg: undefined
                }
                self.currentlyPreppingAction_cells = RecentLocalTxSummary.new_cells(self.ctx, {
                    heading: selectedCcy == Currency.XMR ? "Confirm New Transaction" : "Confirm New Exchange",
                    transactionDetails: transactionDetails,
                    paymentWalletId: selectedWallet._id!,
                    tooltipText: null
                }) // TODO: select Exchange summary here instead if needed
            }
        }
        for (let v of self.currentlyPreppingAction_cells) { 
            /// v.setEnabled(self.isEnabled_formSubmitButtons()) // this set briefly included the form cta so interactivity had to be taken into account
            //
            self.insertSubview_before(v, self.beforePrimaryTransactionConfirmationArea_endMarker) // just inserting before - order is preserved
        }
        AbstractThemed.updateDynamicClassesOfGroupCellsOf(self) // since group members have changed 
        //
        self.configureWith_formSubmittingState({}) // since the interactivity of the buttons may have changed as a consequence
        //
        self.actually_reconstitute_reconstitutable_scrollTop()
    }
    configureWith_recentLocalSendTxActivity()
    {
        const self = this
        //
        self.record_reconstitutable_scrollTop_forNavigatorPopOrRemoval() // so that the page wont jump to the top when this updates
        //
        self.transactionHistory_cells.forEach(v => v.removeFromSuperview()) // there's naturally a more efficient way to implement this but ideally it'd be done with a recycling list view with diffing done by the recycled cell itself
        self.transactionHistory_cells = []
        //
        let selectedWallet = self.wo.selectedWallet()!
        for (let item of selectedWallet.recentSendLocalSendTxActivity.transactionHistory) {
            let cells = RecentLocalTxSummary.new_cells(self.ctx, {
                heading: item.status == SummarizedTransaction_Status.SENDING 
                    ? "New Transaction Summary" 
                    : formatDate__displayableTransaction(item.updated_at),
                transactionDetails: item,
                tooltipText: null, // TODO: possibly add some tooltip content for some of these states
                paymentWalletId: selectedWallet._id!
            }) // TODO: select Exchange summary here instead if needed
            for (let v of cells) {
                self.transactionHistory_cells.push(v)
            }
        }
        let insertAfter_subview = self.formInputsAndWarnings_endMarker
        for (let v of self.transactionHistory_cells) {
            self.insertSubview_after(v, insertAfter_subview)
            insertAfter_subview = v // walk
        }
        AbstractThemed.updateDynamicClassesOfGroupCellsOf(self) // since group members have changed 
        //
        self.actually_reconstitute_reconstitutable_scrollTop()
    }
    //
    override navigationBar_title(): string|undefined
    {
        return "Send Money"
    }
    //
    // Delegation - NavigableView
    override navigationBar_isEnabled_backButton(): boolean
    {
        const self = this
        //
        return true // just always allow 'back' even while sending // was: (see superclass) self.lookup_isFormSubmitting() == true ? false : true
    }
    //
    //
    async didPress_send()
    {
        const self = this
        if (self.isEnabled_formSubmitButtons() != true) {
            throw new Error("Form submission ought to be possible for .didPress_send to be called")
        }
        let weakSelf = new WeakRef(self)
        let selectedWallet = self.wo.selectedWallet()!
        if (selectedWallet.isViewOnlyWallet() == true) {
            alert(
                "Can't Send Using View-Only Wallet",
                "At present, Crossroads cannot create transactions with a view-only wallet. Please select a wallet that has a spend key or seed.",
                [ { text: "Okay", style: 'cancel', onPress: () => {} } ]
            )
            return
        }
        //
        let isSweeping = self.isMaxButtonToggledOn()
        let totalSendable_amt = monero_amount_format_utils__instance.parseMoney(self.ccyAmount())
        let subtotalDependentAmts = self.new_subTotalDependentRetValAmounts(isSweeping, totalSendable_amt)
        //
        let optl__ccyAmount_userInput = self.ccyAmount()
        let optl__ccyAmount_BigInteger = optl__ccyAmount_userInput ? monero_amount_format_utils__instance.parseMoney(optl__ccyAmount_userInput) : undefined
        //
        let hasFullPrimaryDestInfo = SendParams__hasFullPrimaryDestInfo(
            self.toAddr(), 
            optl__ccyAmount_userInput !== 'undefined' ? monero_amount_format_utils__instance.parseMoney(optl__ccyAmount_userInput) : undefined
        )
        let has_any_addtl_dests = (typeof subtotalDependentAmts.xmr_tipAmt_supportMRL !== 'undefined' || typeof subtotalDependentAmts.xmr_tipAmt_supportTheApp !== 'undefined')
        let require_confirm_send = hasFullPrimaryDestInfo || subtotalDependentAmts.xmr_tipAmt_supportMRL || subtotalDependentAmts.xmr_tipAmt_supportTheApp // may as well do it if any destinations exist rather than only if e.g. potentially ambiguous but valid inputs exist
        if (require_confirm_send) { // because otherwise, an error will be shown in a second - besides, we'd have no destination info to show in the alert text
            let descr = `You're about to`
            if (typeof subtotalDependentAmts.xmr_tipAmt_supportMRL !== 'undefined') {
                descr += " donate " + monero_amount_format_utils__instance.formatMoneyFullSymbol(subtotalDependentAmts.xmr_tipAmt_supportMRL) + " to the new Monero Research Fund"
            }
            if (typeof subtotalDependentAmts.xmr_tipAmt_supportTheApp !== 'undefined') {
                if (typeof subtotalDependentAmts.xmr_tipAmt_supportMRL !== 'undefined') {
                    descr += " and"
                }
                descr += " donate " + monero_amount_format_utils__instance.formatMoneyFullSymbol(subtotalDependentAmts.xmr_tipAmt_supportTheApp) + " to support Crossroads"
            }
            if (hasFullPrimaryDestInfo) {
                if (has_any_addtl_dests) {
                    descr += " and"
                }
                descr += " send " + monero_amount_format_utils__instance.formatMoneyFullSymbol(optl__ccyAmount_BigInteger) + " to " + self.toAddr()
            } else if (optl__ccyAmount_userInput && optl__ccyAmount_BigInteger.compare(new BigInteger(0))) {
                descr += " send 0 XMR as a primary destination"
            } else if (self.toAddr()) {
                // otherwise this is actually not a valid transaction and an error will be displayed next
// TODO: ideally we'd detect this before showing an alert 
            } else {
                descr += " WITH NO PRIMARY DESTINATION"
            }
            descr += ". Proceed?"
            alert(
                'Confirm Send',
                descr,
                [
                    { text: "Nevermind", style: 'cancel', onPress: () => {} },
                    {
                        text: 'Proceed',
                        onPress: async () => {
                            // setTimeout(async () => { // PS: this is probably not needed
                                await _really_send()
                            // })
                        }
                    }
                ]
            )
        } else {
            await _really_send()
        }
        async function _really_send()
        {
            let submitTime_state =
            {
                selectedCcy: self.selectedCcy(),
                ccyAmount: optl__ccyAmount_userInput, 
                isMaxButtonToggledOn: isSweeping,
                spend_from_subaddr_maj_i: self.spendFromSubaddrMajIdx()!,
                spend_from_subaddr_min_idxsCSV_OR_all: self.spendFromSubaddrMinIdxsCSV_OR_all()!,
                //
                toAddr: self.toAddr(),
                paymentId: self.paymentId(),
                isButtonPressed_addPID: self.input_paymentId.isButtonPressed_addPID(),
                //
                // TODO: the following 0 -> '' conversion should be factored
                supportTheApp_amt_atmcUnitsStr: subtotalDependentAmts.xmr_tipAmt_supportTheApp ? subtotalDependentAmts.xmr_tipAmt_supportTheApp.toString() : undefined,
                supportMRL_amt_atmcUnitsStr: subtotalDependentAmts.xmr_tipAmt_supportMRL ? subtotalDependentAmts.xmr_tipAmt_supportMRL.toString() : undefined,
            }
            await selectedWallet.send_funds({
                send_params: {
                    selectedCcy: submitTime_state.selectedCcy,
                    ccyAmount_userInput: submitTime_state.ccyAmount, 
                    isSweep: submitTime_state.isMaxButtonToggledOn,
                    spend_from_subaddr_maj_i: submitTime_state.spend_from_subaddr_maj_i,
                    spend_from_subaddr_min_idxsCSV_OR_all: submitTime_state.spend_from_subaddr_min_idxsCSV_OR_all,
                    //
                    toAddr: submitTime_state.toAddr,
                    paymentId_userInput: submitTime_state.paymentId,
                    //
                    supportTheApp_atomicUnitsStr: submitTime_state.supportTheApp_amt_atmcUnitsStr,
                    supportMRL_atomicUnitsStr: submitTime_state.supportMRL_amt_atmcUnitsStr,
                    //
                    optl__local_senderCustomRefToken: undefined // not used here?
                },
                startingSend_fn: (this_processingTxSummaryUUID: string) =>
                {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return // not that this would ever happen here.. unless they hit back, actually?
                    }                
                    optl_self.__mark_form_as_currently_submitting() // mark as currently busy
                    //
                    // zero form state 
                    // it'd be much nicer to batch all of these given the side effects (duplicated work) but.. meh.. maybe the right place to do that is at a -setNeedsDisplay framerated mechanism on self at the configure calls (batch configure calls and ~runloop)
                    optl_self.input_toAddr.setInputValue('')
                    optl_self.input_ccyAmount.setInputValue('')
                    if (submitTime_state.isMaxButtonToggledOn) { // this probably doesn't need to be done
                        optl_self.input_ccyAmount.set_props({ ccyAmount: { 
                            isMaxButtonToggledOn: false, ccy: undefined, title: undefined, tooltipText: undefined 
                        } })
                    }
                    optl_self.input_paymentId.setInputValue('')
                    if (submitTime_state.isButtonPressed_addPID) { // re-press 
                        optl_self.input_paymentId.unset_isButtonPressed_addPID()
                    }
                    //
                    optl_self.configureWith_formSubmitPrepProgress()
                },
                errored_fn: (this_processingTxSummaryUUID: string, err_message: string) => 
                {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return // not that this would ever happen here.. unless they hit back, actually?
                    }      
                    optl_self.__bail_from_form_submission_with({ err_str: err_message }) 
                    //
                    // zero form state 
                    // it'd be much nicer to batch all of these given the side effects (duplicated work) but.. meh.. maybe the right place to do that is at a -setNeedsDisplay framerated mechanism on self at the configure calls (batch configure calls and ~runloop)
                    optl_self.input_toAddr.setInputValue(typeof submitTime_state.toAddr == 'undefined' ? '' : submitTime_state.toAddr)
                    if (submitTime_state.isMaxButtonToggledOn) { // re-press ot
                        optl_self.input_ccyAmount.set_props({ ccyAmount: { 
                            isMaxButtonToggledOn: true, ccy: undefined, title: undefined, tooltipText: undefined 
                        } })
                    } else {
                        optl_self.input_ccyAmount.setInputValue(typeof submitTime_state.ccyAmount == 'undefined' ? '' : submitTime_state.ccyAmount)
                    }

                    if (submitTime_state.isButtonPressed_addPID) { // re-press 
                        optl_self.input_paymentId.set_isButtonPressed_addPID()
                    }
                    optl_self.input_paymentId.setInputValue(submitTime_state.paymentId) // *then* set value if needed
                    //
                    optl_self.configureWith_formSubmitPrepProgress() // since the values are back
                    //
                    // // setTimeout(() => { // TODO: is this desired?
                    // //     alert(err_message)
                    // // }, 30) // ms; time to re-render underneath alert first
                },
                successfullySent_fn: (this_processingTxSummaryUUID) =>
                {
                    let optl_self = weakSelf.deref()
                    if (!optl_self) {
                        return // not that this would ever happen here.. unless they hit back, actually?
                    }                
                    optl_self.configureWith_formSubmittingState({})
                    //
                    optl_self.configureWith_formSubmitPrepProgress() // pretty sure this isnt necessary
                }
            })
        }
    }
}